.map-block-wrapper {
  padding: 0;
  @media (min-width: 1200px) {
    padding: 0 40px;
  }
}

.map-block {
  margin-top: 80px;
}

.map-block__top-content {
  padding: 0 24px;

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
    padding: 0;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

.map-block__top-content-wrapper {
  grid-column-start: 2;
}

.map-block__top-title {
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #00FFFF;
  margin-bottom: 1px;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 23px;
  }
}

.map-block__top-description {
  font-weight: 200;
  font-size: 10px;
  line-height: 13px;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 23px;
  }
}

.map-block__map {
  text-align: center;
  margin-top: 73px;

  @media (min-width: 1200px) {
    margin-top: 227px;
  }

  img {
    max-width: 100%;
  }
}
.map-block__map-image-mobile {
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }

  img{
    width: 100%;
  }
}

.map-block__map-image {
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
}

.map-block__bottom-content {

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

.map-block__bottom-content-wrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-gap: 40px;
  padding: 0 33px;


  @media (min-width: 1200px) {
    grid-column-start: 2;
    margin-top: 88px;
    padding: 0;
  }
}

.map-block__bottom-description {

}

.map-block__bottom-description-text {
  font-size: 6px;
  line-height: 9px;
  letter-spacing: 0.777778px;
  color: #FFFFFF;
  margin-bottom: 15px;

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

.map-block__bottom-contacts {
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.55556px;
  color: #00FFFF;

  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 48px;
  }
}


.map-block__bottom-contacts-social {
  display: flex;
  margin-top: 25px;

  @media (min-width: 1200px) {
    margin-top: 40px;
  }
}

.map-block__bottom-contacts-social-button {
  margin-right: 25px;
  text-decoration: none !important;
}