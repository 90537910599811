.base-button-transparent {
  position: relative;
  //width: 118px;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  font-size: 16px;
  padding: 0;
  color: white;
  border: 1px solid white;
  border-left: 0;
  border-right: 0;
  text-decoration: none !important;
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
  //text-transform: uppercase;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-weight: 600;
  outline: none!important;

  @media (min-width: 1200px) {
    line-height: 76px;
    font-size: 32px;
    height: 78px;
    margin-left: 30px;
    margin-right: 30px;
  }

  &:hover {
    color: #00FFFF;
    border-color: #00FFFF;

    .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
      &:before, &:after {
        border-color: #00FFFF;
      }
    }
  }
}

.base-button-transparent--decoration-right {
  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 80%;
    top: -1px;
    right: -16px;
    z-index: -1;
    border: 1px solid white;
    border-left: 0;
    transform-origin: bottom right;
    transform: skew(15deg, 0deg);
    border-bottom: 0;
    outline: none!important;

    @media (min-width: 1200px) {
      right: -32px;
    }

  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 20%;
    bottom: -1px;
    right: -7.5px;
    z-index: -1;
    border: 1px solid white;
    border-left: 0;
    transform-origin: bottom right;
    transform: skew(-45deg, 0deg);
    border-top: 0;

    @media (min-width: 1200px) {
      bottom: -1px;
      right: -15.5px;
    }
  }
}

.base-button-transparent--decoration-left {


  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 20%;
    top: -1px;
    left: -17px;
    z-index: -1;
    border: 1px solid white;
    border-right: 0;
    transform-origin: bottom left;
    transform: skew(-36deg, 0deg);
    border-bottom: 0;

    @media (min-width: 1200px) {
      left: -32px;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 80%;
    bottom: -1px;
    left: -11px;
    z-index: -1;
    border: 1px solid white;
    border-right: 0;
    transform-origin: bottom left;
    transform: skew(10deg, 0deg);
    border-top: 0;

    @media (min-width: 1200px) {
      left: -21px;
    }
  }
}

.base-button-transparent---blue{
  border: 1px solid #00FFFF;
  border-right: 0;
  border-left: 0;
  color: #00FFFF;
  line-height: 21px;
  .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
    &:before, &:after {
      border-color: #00FFFF;
    }
  }

  &:hover {
    color: white;
    border-color: white;

    .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
      &:before, &:after {
        border-color: white;
      }
    }
  }
}


.base-button-transparent--blue{
  border: 1px solid #00FFFF;
  border-right: 0;
  border-left: 0;
  color: #00FFFF;
  .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
    &:before, &:after {
      border-color: #00FFFF;
    }
  }

  &:hover {
    color: white;
    border-color: white;

    .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
      &:before, &:after {
        border-color: white;
      }
    }
  }
}


