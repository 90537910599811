header{
  position: relative;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  //right: 40px;
  //left: 40px;
  z-index: 10;


  @media (min-width: 1200px) {
    justify-content: flex-end;
    margin-top: 70px;
  }

  .base-logo{

    @media (min-width: 1200px) {
      margin-right: 180px;
    }
  }

  .base-button-transparent{
    margin-right: 0;
  }

  .base-link{
    margin-left: 32px;
  }
}