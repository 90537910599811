body {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000028;
  background-image: url("../images/main_bg.png");
  background-position: top right;
  background-size: contain;
  color: white;
  background-repeat: no-repeat;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding: 0 24px;
  position: relative;

  @media (min-width: 1200px) {
    padding: 0 40px;
  }
}