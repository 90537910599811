.big-title{
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 4.67px;


  @media (min-width: 1200px) {
    font-size: 72px;
    line-height: 88px;
    letter-spacing: 4.66667px;
  }
}