footer{
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-gap: 24px;
  margin-top: 62px;
  padding-bottom: 25px;

  @media (min-width: 1200px) {
    margin-top: 125px;
    padding-bottom: 100px;
    grid-template-columns: 1fr 9fr 2fr;
    grid-gap: 40px;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 1fr 8fr 3fr;
  }
}

.footer-logo{
  @media (min-width: 1200px) {
    grid-column-start: 2;
  }
}

.make-link{
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none!important;

  @media (min-width: 1200px) {
    justify-content: flex-start;
  }

  &:hover{
    .make-link__text{
      border-bottom-color: transparent;
    }
  }
}

.make-link__logo{
  margin-right: 17px;
}

.make-link__text{
  font-size: 10px;
  line-height: 24px;
  margin-top: -15px;
  border-bottom: 1px solid white;
  color: white!important;
  text-decoration: none!important;

  @media (min-width: 1200px) {
    font-size: 14px;
  }
}