.promocode-block {

}

.promocode-block__content {

}

.promocode-block__title-wrapper {

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

.promocode-block__title-block {
  grid-column-start: 2;
  margin-top: 80px;

  @media (min-width: 1200px) {
    margin-top: 0;
  }

  @media (min-width: 1680px) {
    margin-top: 64px;
  }
}

.promocode-block__title-description {
  font-weight: 200;
  margin-top: 18px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.03em;


  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 44px;
    margin-top: 20px;
  }
}


.promocode-block__form-wrapper {

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

.promocode-block__form {
  grid-column-start: 2;
  margin-top: 80px;

  @media (min-width: 1200px) {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-gap: 40px;
  }

  @media (min-width: 1680px) {
    margin-top: 217px;
  }
}

.promocode-block__form--bottom{
  margin-top: 32px;

  @media (min-width: 1200px) {
    margin-top: 0px;
  }

  @media (min-width: 1680px) {
    margin-top: 84px;
  }
}

.promocode-block__form-input {
  height: 29px;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid white !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: white;
  outline: none !important;
  width: 100%;
  padding: 0 24px;

  @media (min-width: 1200px) {
    height: 62px;
    line-height: 42px;
    font-size: 32px;
    width: auto;
    padding: 0;
  }
}

.promocode-block__form-input.error{
  border-bottom-color: red!important;
}

.promocode-block__form-input-description {
  font-size: 8px;
  line-height: 10px;
  margin-top: 8px;
  margin-bottom: 22px;
  margin-left: 24px;

  @media (min-width: 1200px) {
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
    margin-top: 29px;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.success-block {
  margin-top: 80px;

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
    margin-top: 36px;
  }

  @media (min-width: 1680px) {
    grid-template-columns: 3fr 6fr 3fr;
    margin-top: 225px;
  }
}

.success-block__wrapper {
  grid-column-start: 2;
}

.success-block__title {
  font-weight: 200;
  font-size: 18px;
  line-height: 23px;
  color: #00FFFF;
  margin-bottom: 24px;

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 62px;
    margin-bottom: 68px;
  }

  span {
    font-weight: bold;
  }
}

.success-block__description {
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 42px;
  }

  span {
    font-weight: bold;
  }
}

.promocode-block__form-phone-edit-link {
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 8px;

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 21px;
    margin-top: 16px;
  }
}

.promocode-block__timer-block {
  font-weight: 200;
  font-size: 8px;
  line-height: 10px;
  margin-top: 8px;
  margin-bottom: 32px;

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 21px;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .promocode-block__timer{
    font-weight: normal;
  }
}

.promocode-block__resent-sms {
  font-size: 8px;
  line-height: 10px;
  color: #00FFFF;
  margin-top: 8px;
  cursor: pointer;
  margin-bottom: 32px;

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 21px;
    margin-top: 16px;
    margin-bottom: 0;
  }
}